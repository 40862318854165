"use client";

import React, { useContext, useRef, useState, useEffect } from "react";
import { usePathname, useRouter } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  List,
} from "@mui/material";
import { Icon } from "@iconify/react";

import { bottomNavItems, profileItems, profile } from "@/data/MainSidebarItems";
import SocketContext from "@/socket/Context";
import styles from "../styles.module.scss";
import services from "@/store/services";
import Components from "@/components";
import utils from "@/utils";
import apis from "@/api";

function BottomNavbar() {
  const userDetails = useSelector((state) => state.userDetails);
  const { socket } = useContext(SocketContext).SocketState;
  const points = useSelector((state) => state.loc_points);
  const user = useSelector((state) => state.user.users);
  const [theme, setTheme] = useState(utils.getTheme());
  const [topDrawer, setTopDrawer] = useState(false);

  const pointsModal = useSelector((state) => state.modal.pointsModal);

  console.log("user-->", user);
  console.log("userDetails-->", userDetails);
  const pathname = usePathname();
  const dispatch = useDispatch();
  const router = useRouter();
  const sliderRef = useRef();
  const previousLocation = useRef(pathname);

  const handleClick = (item) => {
    setTopDrawer(false);
    switch (item.title) {
      case "My Cipher Point":
        dispatch(services.togglePointsModal());
        break;
      default:
        router.push(item.path);
        break;
    }
  };

  useEffect(() => {
    if (previousLocation.current !== pathname) {
      if (pointsModal) {
        dispatch(services.togglePointsModal(false));
      }
    }

    previousLocation.current = pathname;
  }, [pathname, pointsModal]);

  const onHandleLogout = async () => {
    async function onSuccess() {
      dispatch(services.resetUserDetails());
      dispatch(services.logoutUser());
      router.push("/");
    }
    dispatch(
      services.promiseAlert(
        () => apis.logout(points, socket, user?._id),
        {
          pending: "Logging you out",
          success: "Successfully logged out",
          error: "Can't logout you!",
        },
        onSuccess
      )
    );
  };

  const isShowable = (title) => {
    let res = true;
    if (title === "Trending") {
      if (userDetails?.enrolledToBatch) res = false;
    }
    if (title === "Batches") {
      if (!userDetails?.enrolledToBatch) res = false;
    }
    return res;
  };

  return (
    <>
      {topDrawer && (
        <Slide direction="up" in={topDrawer} container={sliderRef.current}>
          <List
            className={`${styles["menu"]} ${
              pathname === "/"
                ? styles["landing-sidebar"]
                : styles["general-sidebar"]
            }`}
          >
            {user?.email &&
              profileItems.map((item, i) => (
                <ListItem
                  key={i}
                  component={"button"}
                  className={` ${styles["list-item"]} ${styles["bottom"]} ${
                    pathname === item.path ? styles.active : styles.inactive
                  }`}
                  onClick={() => handleClick(item)}
                >
                  <ListItemIcon className={styles["item-icon"]}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
                </ListItem>
              ))}
            <ListItem
              component={"button"}
              className={` ${styles["list-item"]} ${styles["bottom"]}`}
              onClick={() => {
                setTheme(theme === "light" ? "dark" : "light");
                dispatch(
                  services.toggleTheme(
                    theme === "light" ? "dark" : "light",
                    "fixed"
                  )
                );
              }}
            >
              <ListItemIcon className={styles["item-icon"]}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="currentColor"
                    d="M10 3.5a6.5 6.5 0 1 1 0 13v-13ZM10 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16Z"
                  />
                </svg>
              </ListItemIcon>
              <ListItemText className={styles["item-text"]}>
                Theme
                <div id="nav-mode" className={styles["nav-mode"]}>
                  <Icon
                    icon={
                      theme === "light" ? "solar:sun-bold" : "solar:moon-bold"
                    }
                  />
                </div>
              </ListItemText>
            </ListItem>
            <ListItem
              component={"button"}
              className={` ${styles["list-item"]} ${styles["bottom"]} ${styles["log-btn"]}`}
              onClick={
                user?.email
                  ? () => {
                      onHandleLogout();
                      setTopDrawer(false);
                    }
                  : () => {
                      dispatch(services.toggleSigninModal());
                      setTopDrawer(false);
                    }
              }
            >
              <ListItemIcon className={styles["item-icon"]}>
                {user?.email ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 30 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.0303 8.97368C23.0303 7.807 24.4281 7.20869 25.2722 8.01408L28.4777 11.0725C29.3057 11.8625 29.3032 13.1849 28.4722 13.9717L25.2767 16.9977C24.4283 17.8012 23.0303 17.1997 23.0303 16.0312C23.0303 15.296 22.4343 14.7 21.6991 14.7H14.601C13.4964 14.7 12.601 13.8046 12.601 12.7V12.3C12.601 11.1954 13.4964 10.3 14.601 10.3H21.704C22.4365 10.3 23.0303 9.70619 23.0303 8.97368ZM17.4125 19.7824C18.2594 19.2271 19.4002 19.2365 20.1165 19.9523C20.8801 20.7154 20.864 21.9731 19.9909 22.6079C17.7973 24.2026 15.4602 25 12.9798 25C9.3266 25 6.25 23.8042 3.75 21.4125C1.25 19.0208 0 16.0333 0 12.45C0 10.2 0.572391 8.11667 1.71717 6.2C2.86195 4.28333 4.40657 2.77083 6.35101 1.6625C8.29545 0.554167 10.404 0 12.6768 0C15.206 0 17.6285 0.822203 19.9445 2.46661C20.8192 3.08773 20.8497 4.33987 20.0908 5.09828C19.389 5.7997 18.2709 5.80954 17.4454 5.25888C15.9376 4.25296 14.3564 3.75 12.702 3.75C10.1936 3.75 8.0766 4.61667 6.35101 6.35C4.62542 8.08333 3.76263 10.2 3.76263 12.7C3.76263 15.0333 4.65067 17.0417 6.42677 18.725C8.20286 20.4083 10.2862 21.25 12.6768 21.25C14.3417 21.25 15.9203 20.7608 17.4125 19.7824Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 30 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.4125 19.7824C18.2594 19.2271 19.4002 19.2365 20.1165 19.9523C20.8801 20.7154 20.864 21.9731 19.9909 22.6079C17.7973 24.2026 15.4602 25 12.9798 25C9.3266 25 6.25 23.8042 3.75 21.4125C1.25 19.0208 0 16.0333 0 12.45C0 10.2 0.572391 8.11667 1.71717 6.2C2.86195 4.28333 4.40657 2.77083 6.35101 1.6625C8.29545 0.554167 10.404 0 12.6768 0C15.206 0 17.6285 0.822203 19.9445 2.46661C20.8192 3.08773 20.8497 4.33987 20.0908 5.09828C19.389 5.7997 18.2709 5.80954 17.4454 5.25888C15.9376 4.25296 14.3564 3.75 12.702 3.75C10.1936 3.75 8.0766 4.61667 6.35101 6.35C4.62542 8.08333 3.76263 10.2 3.76263 12.7C3.76263 15.0333 4.65067 17.0417 6.42677 18.725C8.20286 20.4083 10.2862 21.25 12.6768 21.25C14.3417 21.25 15.9203 20.7608 17.4125 19.7824Z"
                      fill="currentColor"
                    />
                    <path
                      d="M14.1233 13.9275L17.3288 16.9859C18.1729 17.7913 19.5707 17.193 19.5707 16.0263C19.5707 15.2938 20.1645 14.7 20.897 14.7L28 14.7C29.1046 14.7 30 13.8046 30 12.7L30 12.3C30 11.1954 29.1046 10.3 28 10.3L20.9019 10.3C20.1667 10.3 19.5707 9.70402 19.5707 8.96884C19.5707 7.80031 18.1727 7.19882 17.3243 8.00228L14.1288 11.0283C13.2978 11.8151 13.2953 13.1375 14.1233 13.9275Z"
                      fill="currentColor"
                    />
                  </svg>
                )}
              </ListItemIcon>
              <ListItemText>{user?.email ? "Logout" : "Login"}</ListItemText>
            </ListItem>
          </List>
        </Slide>
      )}
      <List
        className={`${styles.navCard} ${
          pathname === "/"
            ? styles["landing-sidebar"]
            : styles["general-sidebar"]
        }`}
      >
        {bottomNavItems.map(
          (item, i) =>
            isShowable(item.title) && (
              <ListItem
                key={i}
                component={"button"}
                className={` ${styles["list-item"]} ${styles["bottom"]} ${
                  pathname === item.path ? styles.active : styles.inactive
                }`}
                onClick={() => handleClick(item)}
              >
                <ListItemIcon className={`${styles["item-icon"]}`}>
                  {item.icon}
                  <span className={styles["item-text"]}>{item.subtitle}</span>
                </ListItemIcon>
              </ListItem>
            )
        )}
        <ListItem
          component={"button"}
          className={` ${styles["list-item"]} ${styles["bottom"]} ${
            [
              "/profile/me",
              "/profile",
              "/following",
              "/profile/wishlist",
              "/profile/liked",
            ].includes(pathname)
              ? styles.active
              : styles.inactive
          }`}
          onClick={() => setTopDrawer((prev) => !prev)}
        >
          <ListItemIcon className={styles["item-icon"]}>
            {user?.profileImage ? (
              <Components.Avatar
                src={user?.profileImage || ""}
                alt={user?.name}
                loader={"vsm"}
                errStyle={{ fontSize: "1rem" }}
                className={styles["avatar"]}
              />
            ) : (
              profile[0].icon
            )}
            <span className={styles["item-text"]}>{profile[0].subtitle}</span>
          </ListItemIcon>
        </ListItem>
      </List>
    </>
  );
}

export default BottomNavbar;
