"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Image from "next/image";

import styles from "./resources.module.scss";
import Components from "@/components";
import utils from "@/utils";
import apis from "@/api";

// eslint-disable-next-line react/display-name
export const ToggleForm = React.memo((props) => {
  const { assignmentId, close, type, mySubmission } = props;

  const [submission, setSubmission] = useState(mySubmission || "");
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState("");

  const validateSubmissionLink = (submission, type) => {
    const githubRegex = /^https:\/\/github\.com\/.+/;
    const codingRegex = /^https:\/\/compiler\.cipherschools\.com\/.+/;

    if (type === "regular" && !githubRegex.test(submission)) {
      toast.error("GitHub link is required for this submission!");
      return false;
    }
    if (type === "coding" && !codingRegex.test(submission)) {
      toast.error("Please submit a valid CipherSchools compiler link!");
      return false;
    }
    return true;
  };

  const handleSubmission = async () => {
    if (!submission.length || !remarks.length) {
      toast.error("Some required fields are empty!");
      return;
    }

    if (!validateSubmissionLink(submission, type)) return;

    const submissionInfo = {
      id: assignmentId,
      submissionLink: submission,
      remarks,
      type,
    };

    let res;
    setLoading(true);
    if (mySubmission) {
      //Update Submission
      res = await apis.updateBatchSubmission(submissionInfo);
    } else {
      //Add Submission
      res = await apis.addBatchSubmission(submissionInfo);
    }
    if (res.status < 400) {
      toast.success(
        `Submission ${mySubmission ? "updated" : "added"} successfully!`
      );
    } else {
      toast.error(res.data?.error || "Something went wrong, please try again!");
    }
    setLoading(false);
    close();
  };

  return (
    <article className={styles.form}>
      <Components.TextInput
        value={submission}
        onChange={setSubmission}
        label={"Submission Link *"}
        placeholder={"Submit your github file link"}
      />
      <Components.TextArea
        label={"Share your experience *"}
        placeholder={
          "Share your suggestions/feedback after attempting the assignment"
        }
        rows={2}
        value={remarks}
        className={styles.suggestions}
        onChange={(e) => setRemarks(e.target.value)}
      />
      <div className={styles.btns}>
        <Components.Button
          className={styles.btn}
          name={"Cancel"}
          handleClick={close}
        />
        <Components.LoadingBtn
          loading={loading}
          className={loading ? `{${styles.btn} ${styles.submit}}` : ""}
        >
          <Components.Button
            disable={!submission.length}
            handleClick={handleSubmission}
            name={mySubmission ? "Update" : "Submit"}
            className={!loading ? `${styles.btn} ${styles.submit}` : ""}
          />
        </Components.LoadingBtn>
      </div>
    </article>
  );
});

const Resource = (props) => {
  const {
    _id,
    title,
    description,
    expiredAt,
    sharedBy,
    type,
    level,
    ...others
  } = props;
  const user = useSelector((state) => state.user.users);
  const [submission, setSubmission] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const descriptionRef = useRef();

  const [toggle, setToggle] = useState(false);

  const DOT_MENU_OPTIONS = [
    { id: 1, title: "Edit", action: () => others?.handleEdit(_id) },
    { id: 2, title: "Delete", action: () => others?.handleDelete(_id) },
  ];

  const fetchSubmission = useCallback(async () => {
    const res = await apis.getMySubmission(_id);
    if (res.status === 200) setSubmission(res.data.result);
  }, [_id]);

  const increaseClickCount = () => {
    apis.increaseResourceClickCount({ resourceId: _id });
  };

  const handleAction = async (e, type) => {
    if (type === "resource") {
      increaseClickCount();
      window.open(others?.url, "_blank");
    } else {
      if (submission?.status === "expired") {
        toast.warning("Time's up! Submissions are no longer allowed!");
        return;
      }
      setToggle(true);
    }
  };

  useEffect(() => {
    fetchSubmission();
  }, []);

  useEffect(() => {
    if (descriptionRef) {
      description;
    }
    if (descriptionRef.current) {
      descriptionRef.current.innerHTML = `${utils.linkify(description)}`;
    }
  }, [description]);

  return (
    <article className={styles.card} id={`Bat`}>
      <div className={styles.mainContent}>
        <header>
          <div className={styles.mentor}>
            <Image
              src={sharedBy[0].profileImage ?? ""}
              alt={`batch-mentor-${sharedBy[0].name}`}
              width={50}
              height={50}
            />
            <p>
              {sharedBy[0].name}{" "}
              {submission?.status ? (
                submission?.status === "pending" ? (
                  <span>
                    [Due in {utils.timeDifference(expiredAt, new Date(), "")}]
                  </span>
                ) : (
                  <span>
                    [Expired - {utils.formatDate(new Date(expiredAt))}]
                  </span>
                )
              ) : null}
            </p>
          </div>
          {user?._id === sharedBy[0]?._id && (
            <Components.DotMenu options={DOT_MENU_OPTIONS} />
          )}
          {others?.showStatus && !user.isMentor ? (
            <span aria-label={submission?.status} className={styles.status}>
              {submission?.status}
            </span>
          ) : null}
          <p className={`${styles["difficultyLevel"]} ${styles[level]}`}>
            {level && level !== "all"
              ? level.charAt(0).toUpperCase() + level.slice(1)
              : null}
          </p>
        </header>
        <h2>{title}</h2>
        <p
          className={`${styles.description} ${showMore ? styles.showMore : ""}`}
          ref={descriptionRef}
        >
          {/* {linkify(description)} */}
        </p>
        {showMore && (
          <div className={styles["compiler-div"]}>
            {type === "coding" && (
              <>
                <a
                  className={styles.showMore}
                  href="https://compiler.cipherschools.com"
                  target="_blank"
                >
                  Go to Code Editor
                </a>
                <p className={styles.note}>
                  *Please submit the{" "}
                  <a href="https://compiler.cipherschools.com" target="_blank">
                    www.compiler.cipherschools.com
                  </a>{" "}
                  link only.
                </p>
              </>
            )}
          </div>
        )}
        <span onClick={() => setShowMore((prev) => !prev)}>
          Read {showMore ? "less" : "more"}
        </span>
        {!user.isMentor ||
        !submission?.mentors?.map((item) => item._id)?.includes(user._id) ? (
          <div className={styles["submit-action"]}>
            {!others?.url && submission?.submissionLink && !toggle ? (
              <Components.TextInput
                disabled={true}
                value={submission?.submissionLink}
              />
            ) : null}
            {!toggle && submission?.status !== "expired" ? (
              <Components.Button
                className={styles.btn}
                name={
                  others?.url
                    ? "Check Now"
                    : submission?.submissionLink
                      ? "Edit"
                      : "Add Submission"
                }
                handleClick={(e) =>
                  handleAction(e, others?.url ? "resource" : "assignment")
                }
              />
            ) : null}
          </div>
        ) : null}
        {toggle ? (
          <ToggleForm
            close={() => {
              setToggle(false);
              fetchSubmission();
            }}
            assignmentId={_id}
            mySubmission={submission?.submissionLink}
            type={type}
          />
        ) : null}
      </div>
      <div className={styles.footer}>
        <p>Shared on {utils.formatDate(new Date(others?.createdAt))}</p>
        {others?.url ? (
          others.clicks ? (
            <p>
              {others.clicks} View{others.clicks > 1 ? "s" : ""}
            </p>
          ) : null
        ) : others.submissions ? (
          <p>
            {others.submissions} Submission{others.submissions > 1 ? "s" : ""}
          </p>
        ) : null}
      </div>
    </article>
  );
};

export default React.memo(Resource);
